import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import { useSelector, useDispatch } from "react-redux";
import ApiLib from "../../utils/api";
import addToCart from "../../redux/actions/cartActions";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MyCollectionModal from "../../pages/shop-product/MyCollectionModal";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import SharePriceModal from "../../pages/productListing/SharePriceModal";
import mixpanel from "mixpanel-browser";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  pb: 0,
};

const HomeSingleProduct = ({
  product,
  sliderClassName,
  wishlistPrd,
  deletewishlist,
  addwishlist,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const API = new ApiLib();
  const userDetails = useSelector((state) => state.userDetails);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const userInformation = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [quantityCount, setQuantityCount] = useState(1);
  const { addToast } = useToasts();
  const [openCollectionModal, setOpenCollectionModal] = useState(false);
  const [createCollection, setCreateCollection] = useState(false);
  const handleClosecreateCollection = () => setCreateCollection(false);
  const [shareCollection, setShareCollection] = useState(false);
  const handleOpenshareCollection = () => setShareCollection(true);
  const handleCloseshareCollection = () => setShareCollection(false);

  //
  const [showUserModeModal, setShowUserModeModal] = useState(false);
 //

  const [modalOpen,setmodalOpen] = useState(false);
  const [shareData,setShareData] = useState(null);

// 
const handleOpenUsermodeModal = () => {
  if(userDetails.user != null){
  setShareData(product)
  setShowUserModeModal(true)
  }else{
    addToast("Kindly login to share a product", {
      appearance: "warning",
      autoDismiss: true,
    });
  }
};
// 
console.log("shareCollection", shareCollection);
  const GetCartDetails = () => {
    setLoading(true);
    API.GetCart(userDetails.user.ID)
      .then((res) => {
        console.log("get cart", res);
        dispatch(addToCart(res.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const UpdateCart = (item) => {
    setLoading(true);
    let data = {
      ser_no: item.SER_NO,
      userid: userDetails.user.ID,
      dcode: item.DCODE,
      quantity: Number(item.QUANTITY) + quantityCount,
      rate: item.RATE,
    };
    API.UpdateCart(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        if (res.data[0].result == 1) {
          addToast("Cart updated successfully ", {
            appearance: "success",
            autoDismiss: true,
          });
          GetCartDetails();
        } else {
          addToast("Something went wrong please try again", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        addToast("Something went wrong please try again", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const AddToCart = (product) => {
    if (userDetails.user) {
      setLoading(true);
      // if item already present in cart then update cart will work
      let isPresent = cartItems.find((item) => {
        return item.DCODE == product.DCODE;
      });
      console.log("ispresent", isPresent);
      if (isPresent) {
        addToast("Item is already present in the cart", {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        let productDetails = {
          userid: userDetails.user.ID,
          dcode: product.DCODE,
          quantity: quantityCount,
          rate: product.SRATE,
        };
        API.AddToCart(productDetails)
          .then((res) => {
            console.log("added to cart");
            if (res.data[0].result == 1) {
              addToast("Item added to cart successfully ", {
                appearance: "success",
                autoDismiss: true,
              });
              GetCartDetails();
            } else if (res.data[0].result == 2) {
              addToast("Item already present in cart", {
                appearance: "error",
                autoDismiss: true,
              });
            } else {
              addToast("Something went wrong please try again", {
                appearance: "error",
                autoDismiss: true,
              });
            }
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            addToast("Something went wrong please try again", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }
    } else {
      addToast("Kindly login to add this product to Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const CreateModal = (props) => {
    const [collectionName, setCollectionName] = useState();
    const CreateCollection = () => {
      if (collectionName) {
        setLoading(true);
        const data = {
          USERID: userDetails.user.ID,
          COLL_NAME: collectionName,
        };
        API.SaveCollection(data)
          .then((res) => {
            console.log(res);
            addToast("New collection created successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            props.onClose();
            setLoading(false);
            setOpenCollectionModal(true);
            
            //
            mixpanel.track("CreateCollection", { CollectionName: JSON.stringify(collectionName) });  // MixPannel ka code
            //

          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            addToast("Something went wrong please try again", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }
    };
    return (
      <Modal
      
        open={createCollection}
        onClose={handleClosecreateCollection}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="create-collection-box-modal" >
          <div className="row">
            <div className="col-10 text-left">
              <h3 className="mr-3 title-text-bold sidebar-title">
                Create Collection{" "}
              </h3>
            </div>
            <div className=" col-2 text-right mt-n2 ">
              <CloseIcon
                onClick={handleClosecreateCollection}
                className="close-button"
              />
            </div>{" "}
          </div>

          <div className="row">
            <div className="col-12 px-4 py-4 mb-3">
              <input
                type="text"
                // Bug Solve By Syed - Create Collection
                onChange={(e) => {
                  const input = e.target.value.trim(); // Remove leading and trailing spaces
                  if (
                    input.length > 0 &&
                    !input.startsWith(".") &&
                    !input.startsWith(",") &&
                    !input.startsWith("@")
                  ) {
                    setCollectionName(input);
                  }
                }}// Bug Solve By Syed
          onKeyDown={(e) => {
        if (e.key === "Enter") {
          CreateCollection();
        }
      }}
      //
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Collection Name"
                autoFocus
              />
            </div>
          </div>
          <div
            className="row bg-black  text-center save-btn"
            style={{ marginLeft: "-33px", marginRight: "-33px" }}>
            <div
              className="col-12 px-3 py-3 share-button text-white btn-background"
              onClick={() => {
                CreateCollection();
              }}>
              Save
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const OpenCollection = () => {
    if (userDetails.user != null) {
      setOpenCollectionModal(true);
    } else {
      addToast("Kindly login to view/add collection", {
        appearance: "warning",
        autoDismiss: true,
      });
      // Notify({
      //   alert: true,
      //   type: "warning",
      //   title: "Kindly login to view/add collection",
      // });
    }
  };
  const [shareLink, setShareLink] = useState();
  useEffect(() => {
    if (product.BASEDESIGN) {
      CreateShareLink();
    }
    //eslint-disable-next-line
  }, [product.BASEDESIGN]);
  const CreateShareLink = () => {
    console.log("USER VAL ->", userInformation?.user);
    let userId =
      userInformation?.user !== null
        ? Buffer.from(`${userInformation?.user?.ID}` || "0").toString("base64")
        : Buffer.from("0").toString("base64");
    console.log(userId, "Checkits Value");
    let link = `https://catalogcollection.web.app/catalouge/product/${userId}/${product.BASEDESIGN}/${product.DCODE}`;
    setShareLink(link);
  };

  const ShareModal = () => {
    const userDetails = useSelector((state) => state.userDetails);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleWaClick = () => {
      let userPhone = userDetails.user.MOBNO;

      if (userPhone === "") return;

      let link = `https://wa.me/${userPhone}`;
      window.open(link, "_blank");
    };

    return (
      <Modal
        open={shareCollection}
        onClose={handleCloseshareCollection}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="share-on-whatsApp" >
          <div className="row">
            <div className="col-8 text-left">
              <div className="row align-items-center mb-10">
                <h4
                  className="mr-3 font-weight-bold share-button sidebar-title custom_share_link_button"
                  onClick={handleWaClick}
                  style={{ marginBottom: 0 }}>
                  Share on Whatsapp
                </h4>
                <WhatsAppIcon
                  className="custom_icon_size icon-color pointer"
                  onClick={handleWaClick}
                />
              </div>
            </div>
            <hr />

            <div className=" col-4 text-right mt-n2 ">
              <CloseIcon
                className=" close-button"
                onClick={handleCloseshareCollection}
              />
            </div>
          </div>
          {/* <div className="row text-center mt-4 mb-4">
            <div className="col-3">
              <InstagramIcon className="icon-color" />
            </div>
            <div className="col-3">
              <EmailIcon className="icon-color" />
            </div>
            <div className="col-3">
              <FacebookIcon className="icon-color" />
            </div>
            <div className="col-3">
              <WhatsAppIcon
                className="custom_icon_size icon-color pointer"
                onClick={handleWaClick}
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-12 mb-2 title-text-bold">Product Link</div>
            <div className="col-10 pr-0 mb-3">
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={shareLink}
                placeholder="https://zjwel.com/ddf/dfdf/swd....."
              />
            </div>
            <div className="col-2  mt-2">
              <ContentCopyOutlinedIcon
                onClick={(event) => {
                  navigator.clipboard.writeText(shareLink);
                  handleClick(event);
                }}
                className="icon-color pointer"
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}>
                <Typography sx={{ p: 2 }}>{"Item link copied"}</Typography>
              </Popover>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <Fragment>
    
      {/* <ShareModal /> */}

    <SharePriceModal
    onClose={() => setShowUserModeModal(false)}
    show={showUserModeModal}

    //  Open={modalOpen} 
     data={shareData} 
    //  setmodalOpen={setmodalOpen} 
      />

      <MyCollectionModal
        open={openCollectionModal}
        close={() => setOpenCollectionModal(false)}
        product={product}
        openAddCollection={() => {
          setCreateCollection(true);
          setOpenCollectionModal(false);
        }}
        closeaAddCollection={() => setCreateCollection(false)}
      />
      <CreateModal
        onClose={() => setCreateCollection(false)}
        open={createCollection}
        openCollection={() => {
          setCreateCollection(false);
          setOpenCollectionModal(true);
        }}
      />
      <div className="">
        {/* <div className="col-xl-3 col-md-4 col-lg-4 col-sm-4 "> */}
        <div>
          <div className={` product-wrap mx-4 `} style={{ width: "80%" }}>
            <div className="product-img">
              <Link
                to={
                  process.env.PUBLIC_URL +
                  "/product/" +
                  product.BASEDESIGN +
                  "/" +
                  product.DCODE
                }>
                <img
                  className="default-img img-thumbnail"
                  src={product.DIMAGE1}
                  alt=""
                />
                {product?.DIMAGE1 ? (
                  <img className="hover-img" src={product?.DIMAGE1} alt="" />
                ) : (
                  ""
                )}
              </Link>
              <div
                style={{
                  cursor: "pointer",
                }}

                // onClick={() => handleOpenshareCollection()}
                // onClick={() => handleCloseShareModal()}
                onClick={() => handleOpenUsermodeModal()}

                className="product-img-badges">
                <span className="pink share-icon">
                  <ShareIcon sx={{ color: "#14716b" }} />
                </span>
              </div>

              <div className="product-action">
                <div className="pro-same-action pro-wishlist">
                  {!wishlistPrd ? (
                    <button
                      // className={wishlistItem !== undefined ? "active" : ""}
                      // disabled={wishlistItem !== undefined}
                      // title={
                      //   wishlistItem !== undefined
                      //     ? "Added to wishlist"
                      //     : "Add to wishlist"
                      // }
                      onClick={() => addwishlist(product)}>
                      <i className="pe-7s-like" />
                    </button>
                  ) : (
                    <button
                      // className={wishlistItem !== undefined ? "active" : ""}
                      // disabled={wishlistItem !== undefined}
                      // title={
                      //   wishlistItem !== undefined
                      //     ? "Added to wishlist"
                      //     : "Add to wishlist"
                      // }
                      onClick={() => deletewishlist(product.DCODE)}>
                      <FavoriteIcon className="icon-color-wishlist" />
                    </button>
                  )}
                </div>
                <div className="pro-same-action pro-cart ">
                  <button
                    className="custom_addtocartbtnhome"
                    onClick={() => AddToCart(product)}
                    // title="Add to cart"
                  >
                    {" "}
                    <i className="pe-7s-cart"></i> Add to cart
                  </button>
                </div>
                <div className="pro-same-action pro-quickview">
                  <button
                    onClick={() => OpenCollection()}
                    title="Add To Collection">
                    <LibraryAddOutlinedIcon />{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="product-content-2">
              <div className={`title-price-wrap-2 `}>
                <div className="product-content text-center">
                  <h3>{product.INAME}</h3>
                  {/* {product.rating && product.rating > 0 ? (
              <div className="product-rating">
                <Rating ratingValue={product.rating} />
              </div>
            ) : (
              ""
            )} */}
                  {console.log("PRODUCT", product)}
                  <div className="product-price custom_price">
                    {" "}
                    {userDetails?.user !== null ? (
                      userDetails?.user?.RATEMODE === "3" ? (
                        <p className="custom_fontweight_price">
                          {" "}
                          ₹ {product?.SRATE}
                        </p>
                      ) : userDetails?.user?.RATEMODE === "2" ? (
                        <p className="custom_fontweight_price">
                          ₹ {product?.SRATE1}
                        </p>
                      ) : (
                        <p className="custom_fontweight_price">
                          ₹ {product?.SRATE1}
                        </p>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product modal */}
      {/* <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedprice={discountedPrice}
        finalproductprice={finalProductPrice}
        finaldiscountedprice={finalDiscountedPrice}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtocart={addToCart}
        addtowishlist={addToWishlist}
        addtocompare={addToCompare}
        addtoast={addToast}
      /> */}
    </Fragment>
  );
};

export default HomeSingleProduct;
