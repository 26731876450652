import PropTypes from "prop-types";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Mixpanel } from "../../utils/mixpannel";
import { useDispatch, useSelector } from "react-redux";
import { setSelectAllAction } from "../../redux/actions/selectAllAction";

// import { multilanguage } from "redux-multilanguage";


const ClickOnContactUs = () => {
  Mixpanel.track('ContactUs');
};
const ClickOnAboutUs = () => {
  Mixpanel.track('AboutUs');
};


const ClickOnCollection = () => {
  Mixpanel.track('Colletion_View');
}





const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {

  const mylanguage = useSelector((state) => state.language);
  const dispatch=useDispatch()

  const ClickOnShop = () => {
    Mixpanel.track('ShopPLP');
    dispatch(setSelectAllAction([]))
    
  };


  const NavbarHome = {
    English:"Home",
Hindi: "होम",
Tamil: "வீடு",
Telugu: "ఇంటి",
French: "Accueil",
Spanish: "Inicio",
  }

  const ShopHome = {
English:"Shop",
Hindi: "शॉप",
Tamil: "ஷாப்",
Telugu: "షాపు",
French: "Magasin",
Spanish: "Tienda",
  }

  const CollectionHome = {
 English:"Collection",
 Hindi: "कलेक्शन",
 Tamil: "சேகரிப்பு",
 Telugu: "సంగ్రహం",
 French: "Collection",
 Spanish: "Colección",
  }

  const AboutusHome = {
    English:"About Us",
Hindi: "हमारे बारे में",
Tamil: "எங்கள் பற்றி",
Telugu: "మా గురించి",
French:"proposdenous",
Spanish: "Sobrenosotros",
  }

  const ContactUsHome = {
English:"Contact Us",
Hindi: "हमसे संपर्क करें",
Tamil: "தொடர்பு", 
Telugu: "సంప్రదించండి", 
French: "Contactez-nous",
Spanish: "Contáctenos",
  }


  const NewnavbarHome = NavbarHome[mylanguage] 
  const NewnavbarShop = ShopHome[mylanguage]
  const NewnavbarCollection = CollectionHome[mylanguage]
  const newAboutus = AboutusHome[mylanguage]
  const newContactus = ContactUsHome[mylanguage]



  const { pathname } = useLocation();
  // #237a75
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          <li>
            <Link
              to={process.env.PUBLIC_URL + "/home"}
              style={{ color: pathname === "/home" ? "#13706a" : "#555252" }}
            >
              {NewnavbarHome}
            </Link>
          </li>
          <li>
            <Link
              to={process.env.PUBLIC_URL + "/products"}
              style={{
                color: pathname === "/products" ? "#13706a" : "#555252",
              }}
              onClick={ClickOnShop}
            >
              {" "}
              {NewnavbarShop}
              {/* {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )} */}
            </Link>
            {/* <ul className="mega-menu">
              <div className="row">
                <div className="col-2">
                  <h5 className="text-bold">Selection</h5>
                  <ul className="text-left">
                    <li className="menu-titles">New arrival</li>
                    <li className="menu-titles">Most Popular </li>
                    <li className="menu-titles">In Offer</li>
                  </ul>
                </div>
                <div className="col-2">
                  <h5 className="text-bold">By Categories</h5>
                  <ul className="text-left">
                    <li className="menu-titles">Rings</li>
                    <li className="menu-titles">Bracelets </li>
                    <li className="menu-titles">Customized Jewellery</li>
                    <li className="menu-titles">Earings</li>
                    <li className="menu-titles">Necklaces</li>
                    <li className="menu-titles">Earcuffs</li>
                    <li className="menu-titles"> All Jewellery</li>
                  </ul>
                </div>
                <div className="col-2">
                  <h5 className="text-bold">By Material</h5>
                  <ul className="text-left">
                    <li className="menu-titles">Gold</li><br/>
                    <li className="menu-titles">Silver </li><br/>
                    <li className="menu-titles">Platinum</li><br/>
                  </ul>
                </div>
                <div className="col-2">
                  <img
                    src={img10}
                    className="attachment-large size-large img-thumbnail mt-4 pt-4"
                    alt=""
                    loading="lazy"
                    srcSet={img10}
                  />
                  <h5 className="mt-2 font-weight-bold">Combo Offers</h5>
                </div>
                <div className="col-2">
                  
                  <img
                    src={img10}
                    className="attachment-large size-large img-thumbnail mt-4 pt-4"
                    alt=""
                    loading="lazy"
                    srcSet={img10}
                  />
                  <h5 className="mt-2 font-weight-bold">In Discount</h5>
                  
                </div>
                <div className="col-2">
                  <img
                    src={img9}
                    className="attachment-large size-large img-thumbnail mt-4 pt-4"
                    alt=""
                    loading="lazy"
                    srcSet={img9}
                  />
                  <h5 className="mt-2 font-weight-bold">Browse in Top Sellers</h5>

                </div>
              </div>
            </ul> */}
          </li>

          <li>
            <Link
              to={process.env.PUBLIC_URL + "/Collection"}
              style={{
                color: pathname === "/Collection" ? "#13706a" : "#555252",
              }}
              onClick={ClickOnCollection}
            >
              {NewnavbarCollection}
            </Link>
          </li>
          <li>
            <Link
              to={process.env.PUBLIC_URL + "/about"}
              style={{
                color: pathname === "/about" ? "#13706a" : "#555252",
              }}
              onClick={ClickOnAboutUs}
            >
              {newAboutus}
            </Link>
          </li>
          <li>
            <Link
              to={process.env.PUBLIC_URL + "/contact"}
              style={{
                color: pathname === "/contact" ? "#13706a" : "#555252",
              }}
              onClick={ClickOnContactUs} // Track Mixpanel event on click

            >
              {newContactus}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};

export default NavMenu;
