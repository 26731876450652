import PropTypes from "prop-types";
import React from "react";
import { useHistory } from 'react-router-dom'; // Import useHistory from React Router


const HeroSliderSingle = ({ data, sliderClass,index}) => {

  const history = useHistory();
  const handleClick = () => {
    // Check if the index corresponds to the first image (index 0)
    if (index === 0) {
      // Navigate to the product page when the first image is clicked
      history.push('/products'); // Replace '/product' with the actual product page URL
    // } else {
    //   // Perform other actions when images other than the first one are clicked
    //   if (typeof onClick === 'function') {
    //     onClick();
    //   }
    }else if(index === 1) {
      // Replace 'your_playstore_url' with the actual URL of your app on the Play Store
      const playStoreURL = 'https://play.google.com/store/apps/details?id=com.amyra';
      window.open(playStoreURL, '_blank');
    }
    
  };

  return (
    <div
      className={`single-slider-2 slider-height-2 d-flex align-items-center bg-img ${
        sliderClass ? sliderClass : ""
      }`}
      // style={{ backgroundImage: `url(${process.env.PUBLIC_URL + data.image})` }}
      style={{
        // backgroundImage: `url(${data})`,
        height: "90vh !important",
        width: "100%",
        cursor: index === 0 || index === 1 ? 'pointer' : 'default',
      }} onClick={handleClick}>
      <img src={data} alt="bannerimages" loading="lazy" style={{ maxWidth: "100%", height: "auto" }} />
    </div>
  );
};
HeroSliderSingle.propTypes = {
  data: PropTypes.object,
  sliderClass: PropTypes.string,
};

export default HeroSliderSingle;
