import React, { Fragment, useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import HeroSliderSingle from "../../components/hero-slider/HeroSliderSingle.js";

import BannerImageOne from '../../assets/img/amyraHome/BannerBgImageOne.webp'
import BannerImageTwo from '../../assets/img/amyraHome/BannerBgImageTwo.webp'
import BannerImageThree from '../../assets/img/amyraHome/BannerBgImageThree.webp'


const HomePageSlider = () => {

  // Here i change the banner images from backend to handle in front end
  const BannerNewImages = [BannerImageOne,BannerImageTwo,BannerImageThree];
  //

  // const API = new ApiLib();
  // const arr = [HeroImage, bannerImage, HeroImage];
  const [bannerArr, setBannerArr] = useState([]);
  const [loading, setLoading] = useState();
  // const [params, setParams] = useState();
  const params = {
    shouldSwiperUpdate: true,
    effect: "fade",
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // containerClass: "swiper-container-class",
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left font-weight-bold text-light " />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right font-weight-bold text-light " />
      </button>
    ),
  };

  // useEffect(() => {
  //   if (bannerArr.length) {
  //     setParams(
  // }, [bannerArr]);
  // useEffect(() => {
  //   FetchBanner();
  //   //eslint-disable-next-line
  // }, []);

  // const FetchBanner = () => {
  //   setLoading(true);
  //   API.Banner(1)
  //     .then((res) => {
  //       console.log("banner img", res);
  //       let imgArr = res.data.map((img) => {
  //         return img.IMG;
  //       });
  //       setBannerArr(imgArr);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // };
  console.log("banner arr", bannerArr);
  return (
    !loading && (
      <div className="slider-area">
        <div className="slider-active nav-style-1">
          <Swiper {...params}>
            {BannerNewImages.map((single, key) => {
              return (
                <HeroSliderSingle
                  data={single}
                  key={key}
                  sliderClass="swiper-slide"
                  index={key}  
                />
              );
            })}
          </Swiper>
        </div>
      </div>
    )
  );
};

export default HomePageSlider;
